<template>
<div>
    <Loading v-if="$global.state.loading" />

    <!-- filters -->
    <v-card outlined flat class="mb-4 py-1 px-3 font-weight-bold">
        <v-row>
            <v-col cols="2">
                <v-select v-model="selectedOrderStatus" :items="orderStatus" item-text="text" item-value="value"
                    color="indigo" dense label="الحالة" no-data-text="لا توجد بيانات" hide-details
                    prepend-inner-icon="fi fi-br-building" outlined filled
                    @change="filterOrderStatus(selectedOrderStatus)" />
            </v-col>
            <v-col cols="2">

                <v-select v-model="selectedOrderType" :items="ordersTypes" item-text="text" item-value="value"
                    color="indigo" dense label="نوع الطلبية" no-data-text="لا توجد بيانات" hide-details
                    prepend-inner-icon="fi fi-br-building" outlined filled
                    @change="filterOrdersTypes(selectedOrderType)" />
            </v-col>
            <v-col cols="2">

                <v-text-field v-debounce:500ms="getItems" v-model="$global.state.filter.search" label="ابحث هنا"
                    hide-details prepend-inner-icon="search" dense color="info" outlined filled />
            </v-col>
            <v-col cols="3">
                <v-autocomplete v-model="$global.state.clientId" @input="getItems()" :items="$global.state.listClients"
                    :loading="$global.state.loading" clearable item-text="fullName" item-value="id" color="indigo" dense
                    label="الزبائن" no-data-text="لا توجد بيانات" hide-details prepend-inner-icon="fi fi-br-users"
                    outlined filled />
            </v-col>
            <v-col cols="3">
                <v-autocomplete v-model="$global.state.provinceId" @input="getItems()" :items="$global.state.provinces"
                    :loading="$global.state.loading" clearable item-text="name" item-value="id" color="indigo" dense
                    label="المحافظة" no-data-text="لا توجد بيانات" hide-details prepend-inner-icon="fi fi-br-building"
                    outlined filled />
            </v-col>
        </v-row>
    </v-card>
    <!-- ./ filters -->
    <v-card outlined flat class="mb-4 py-1 px-3 font-weight-bold">
        <v-row>
            <v-col cols="2" class="my-auto">
                <label>عمليات المجموعة</label>
            </v-col>
            <v-col cols="2">
                <v-btn color="primary" class="btn secondary--text" :disabled="selected.length == 0"
                    @click="openStatusChangeDialog">
                    تغيير حالة الطلبية
                </v-btn>
            </v-col>
        </v-row>
    </v-card>
    <v-card id="card">
        <v-data-table class="mytable" v-model="selected" :items="$global.state.orders" :loading="$global.state.loading"
            :headers="headers" :search="$global.state.filter.search" hide-default-footer
            loading-text="جاري تحميل البيانات" no-data-text="لا توجد بيانات"
            :id="$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'"
            :items-per-page="$global.state.filter.pageSize" @click:row="getDetails" show-select
            style="cursor: pointer;">

            <template v-slot:item.totalPrice="{ item }">
                <h5 v-if="item.totalPrice != item.totalPriceAfterDiscount"
                    class="text-decoration-line-through grey--text">{{ item.totalPrice | formatCurrency }} د.ع</h5>
                <div class="green--text">{{ item.totalPriceAfterDiscount | formatCurrency }} د.ع</div>
            </template>

            <template v-slot:item.orderNo="{ item }">
                <div>{{ item.orderNo }}</div>
                <h5 class="grey--text">{{ new Date(item.created).toISOString().substring(0, 10) }}</h5>
            </template>

            <template v-slot:item.recipientPhoneNo1="{ item }">
                <div dir="ltr" class="text-right">{{ item.recipientPhoneNo1 | phone }}</div>
                <div dir="ltr" class="text-right">{{ item.recipientPhoneNo2 }}</div>
            </template>

            <template v-slot:item.description="{ item }">
                <div>{{ item.description ? item.description.substring(0, 50) + ' ...' : item.description }}</div>
            </template>

            <template v-slot:item.fromOffers="{ item }">
                <span v-if="item.fromOffers">
                    <v-icon color="green">fi fi-br-check</v-icon>
                </span>
            </template>

            <template v-slot:item.status="{ item }">
                <v-chip small label v-if="item.status == 0" color="primary lighten-1 secondary--text">طلبية
                    جديدة</v-chip>
                <v-chip small label v-if="item.status == 1" color="orange lighten-2">قيد التصميم</v-chip>
                <v-chip dark small label v-if="item.status == 2" color="indigo lighten-2">قيد الطباعة</v-chip>
                <v-chip small label v-if="item.status == 3" color="info">قيد التوصيل</v-chip>
                <v-chip small label v-if="item.status == 4" color="grey lighten-2">منجزة</v-chip>
                <v-chip small label v-if="item.status == 5" color="error">ملغاة</v-chip>
            </template>

            <template v-slot:item.actions="{ item, index }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn @click.stop="deleteItem(item.id, index,)" v-on="on" icon>
                            <v-icon color="error">delete</v-icon>
                        </v-btn>
                    </template>
                    <span>حذف</span>
                </v-tooltip>
                <!-- 
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn @click.stop="openEditDialog(item)" v-on="on" icon>
                            <v-icon color="indigo">edit</v-icon>
                        </v-btn>
                    </template>
                    <span>تعديل</span>
                </v-tooltip> -->
            </template>
        </v-data-table>
    </v-card>

    <div class="d-flex justify-space-between py-3">
        <v-spacer></v-spacer>
        <Pagination />
    </div>
    <StatusChangeDialog v-on:refresh="refresh" />
</div>
</template>

<script>
import StatusChangeDialog from './statusChangeDialog.vue';
export default {
    components: {
        StatusChangeDialog
    },
    data() {
        return {
            selected: [],
            headers: [
                {
                    text: "رقم الطلبية",
                    value: "orderNo",
                },
                {
                    text: "اسم الطلبية",
                    value: "orderName",
                },
                {
                    text: "الزبون",
                    value: "fromUserFullName",
                },
                {
                    text: "السعر",
                    value: "totalPrice",
                },
                {
                    text: "عنوان التوصيل",
                    value: "deliveryAddress",
                },
                {
                    text: "رقم الهاتف",
                    value: "recipientPhoneNo1",
                },
                {
                    text: "من العروض",
                    value: "fromOffers",
                },
                {
                    text: "الحالة",
                    value: "status",
                    align: "center",
                },

                {
                    text: "الإجراءات",
                    value: "actions",
                    sortable: false,
                    align: "center",
                },
            ],
            show: false,
            orderStatus: [
                {
                    text: "الكل",
                    value: "",
                },
                {
                    text: "طلبية جديدة",
                    value: 0,
                },
                {
                    text: "قيد التصميم",
                    value: 1,
                },
                {
                    text: "قيد الطباعة",
                    value: 2,
                },
                {
                    text: "قيد التوصيل",
                    value: 3,
                },
                {
                    text: "منجزة",
                    value: 4,
                },
                {
                    text: "ملغاة",
                    value: 5,
                },
            ],
            ordersTypes: [
                {
                    text: "الكل",
                    value: 0,
                },
                {
                    text: "الطلبات المكررة",
                    value: 1,
                },
                {
                    text: "من العروض",
                    value: 2,
                },
            ],
            selectedOrderStatus: "",
            selectedOrderType: 0
        };
    },

    mounted() {
        this.$global.state.filter.pageSize = 100;
        this.getItems();
        this.getListClients();

        this.$eventBus.$on(`refresh`, () => {
            this.getItems();
        });
    },

    beforeDestroy() {
        this.$eventBus.$off(`refresh`);
    },

    methods: {
        refresh() {
            this.selected = [];
            this.getItems();
        },
        async getItems() {
            await this.$global.dispatch(`get${this.$route.meta.endPoint}`);
        },

        getListClients() {
            this.$global.dispatch(`getListClients`);
        },

        async deleteItem(id, i) {
            this.$genericService.swalAlertConfirm({}).then((result) => {
                if (result.isConfirmed) {
                    this.$genericService
                        .delete(`${this.$route.meta.endPoint}`, id)
                        .then((res) => {
                            // this.$genericService.swalAlertSuccess();
                            this.$store.dispatch("toastification/setToast", {
                                message: `${res.data.message}`,
                                type: "success",
                            });
                            this.$global.state.products.splice(i, 1);
                        })
                        .catch((err) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `${err.data.message}`,
                                type: "error",
                            });
                        });
                }
            });
        },

        filterOrderStatus(value) {
            this.$global.state.duplicated = "";
            this.$global.state.fromOffers = "";
            this.$global.state.orderStatus = value;
            this.selectedOrderType = 0;
            this.getItems();
        },

        filterOrdersTypes(value) {
            if (value == 1) {
                this.$global.state.duplicated = true;
                this.$global.state.fromOffers = "";
            }
            if (value == 2) {
                this.$global.state.duplicated = "";
                this.$global.state.fromOffers = true;
            }
            this.$global.state.orderStatus = "";
            this.selectedOrderStatus="";
            this.getItems();
        },

        getDetails(item) {
            this.$router.push({
                path: `/orderDetails/${item.id}`,
                params: { id: item.id },
            });
        },

        openAddDialog() {
            var item = {
                dialogType: "add",
            };
            this.$store.commit("setItemDetails", item);
            this.$store.commit("setDialog");
        },

        openStatusChangeDialog() {
            const item = {
                orders: this.selected
            }
            this.$store.commit("setItemDetails", item);
            this.$store.commit("setDialog");
            // this.$eventBus.$emit("fill-fields");
        },
    },
};
</script>

<style scoped></style>
