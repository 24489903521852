<template>
<div>
    <v-dialog v-model="$store.state.dialog" width="50%" transition="dialog-top-transition">
        <v-card flat class="card">
            <v-toolbar outlined flat color="grey lighten-4">
                <v-toolbar-title>
                    تغير حالة الطلبيات لمجموعة الزبائن
                </v-toolbar-title>
                <v-spacer />
                <v-btn @click="close()" icon>
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-form @submit.prevent="submit()" ref="form1">
                <v-container>
                    <v-card outlined>
                        <v-container>
                            <v-row no-gutters>
                                <!-- states -->
                                <v-col cols="12" md="12">
                                    <h4 class="mb-2">
                                        الحالة
                                        <span class="required">*</span>
                                    </h4>
                                    <v-chip-group column v-model="selectedStatus" class="mr-n2">
                                        <v-chip label :value="0" filter outlined>طلبية جديدة</v-chip>
                                        <v-chip label :value="1" filter outlined>قيد التصميم</v-chip>
                                        <v-chip label :value="2" filter outlined>قيد الطباعة</v-chip>
                                        <v-chip label :value="3" filter outlined>قيد التوصيل</v-chip>
                                        <v-chip label :value="4" filter outlined>منجزة</v-chip>
                                        <v-chip label :value="5" filter outlined>تم الغاء الطلبية</v-chip>
                                    </v-chip-group>
                                </v-col>

                                <!-- sendNotify -->
                                <v-col cols="12" md="12">
                                    <v-checkbox color="info" v-model="sendNotify"
                                        label="إرسال إشعار إلى الزبون"></v-checkbox>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>

                    <!-- actions -->
                    <v-col cols="12" md="12">
                        <v-row class="mt-2">
                            <v-btn type="submit" :loading="$global.state.loading" x-large color="primary"
                                class="btn secondary--text" :disabled="selectedStatus==-1">حفظ</v-btn>
                            <v-btn @click="close()" x-large color="error" class="mr-2 btn" outlined>إلغاء</v-btn>
                        </v-row>
                    </v-col>
                </v-container>
            </v-form>

        </v-card>
    </v-dialog>
</div>
</template>
<script>
export default {
    data() {
        return {
            orders: [],
            selectedStatus: -1,
            sendNotify: false,
            valid: false,
            item: {},
        }
    },
    watch: {
        '$store.state.dialog': function (newVal, oldVal) {
            if (newVal) {
                this.orders = this.$store.state.itemDetails.orders;
                this.selectedStatus = -1;
                this.sendNotify = false;
                this.valid = false;

            }
        }

    },
    mounted() {

    },
    methods: {

        close() {
            this.$store.commit("setDialog");
            // this.resetValidation();
            this.$store.commit("setItemDetails", {});
            this.orders = [];
            this.sendNotify = false;
            this.selectedStatus = -1
        },
        async submit() {
            this.$global.state.loading = true;
            this.item.ordersStatus = this.selectedStatus;
            const orderIds = this.orders.map(x => x.id);
            this.item.orderIds = orderIds;
            this.item.sendNotify = this.sendNotify;

            await this.$http
                .post(
                    `${this.$route.meta.endPoint}/changeStatusOrder`,
                    this.item
                )
                .then((res) => {
                    this.close();
                    this.$store.dispatch("toastification/setToast", {
                        message: `تم تحديث حالة الطلبية بنجاح`,
                        type: "success",
                    });
                    this.$emit("refresh")
                    this.orders = [];
                    this.sendNotify = false;
                    this.selectedStatus = -1
                    this.valid = false;
                    this.$refs.form1.resetValidation()
                })
                .catch((err) => {
                    this.$store.dispatch("toastification/setToast", {
                        message: `${err.data.message}`,
                        type: "error",
                    });
                })
                .finally(() => (this.$global.state.loading = false));
        },

    },
};
</script>